<template>
	<div>
		<!-- <div class="workLine">
			<el-card class="workLine" style="width: 20%" shadow="always">发起人</el-card>
		</div> -->
		<el-card style="width: 30%" class="box-card custCard workLine">
			<div slot="header" class="clearfix">
				<span>发起人</span>
			</div>
			<div style="text-align: left" v-if="orgItems.length > 0">
				<div style="display: inline-flex; margin-bottom: 10px">部门：</div>
				<div style="display: inline-grid; margin-bottom: 10px">
					<el-tag style="margin-right: 10px" v-for="(tag, index) in orgItems" :key="tag.id + index" type="primary">{{
						tag.orgName
					}}</el-tag>
				</div>
			</div>
			<div style="text-align: left" v-if="roleItems.length > 0">
				<div style="display: inline-flex; margin-bottom: 10px">角色：</div>
				<div style="display: inline-grid">
					<el-tag style="margin-right: 10px" v-for="(tag, tagiIndex) in roleItems" :key="tagiIndex" type="primary">{{ tag.name }}</el-tag>
				</div>
			</div>
		</el-card>
		<div class="addLine"></div>
		<div class="workLine">
			<el-card class="workLine" style="width: 20%" shadow="always">提交审批</el-card>
		</div>
		<div v-show="newCardList.length == 0">
			<div class="addNode">
				<div class="addLine"></div>
				<div class="addbtn">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="handleDialog(0, 'top')" circle></el-button>
				</div>
				<div class="addLine"></div>
			</div>
		</div>
		<div v-for="(card, index) in newCardList" :key="index">
			<div class="addNode">
				<div class="addLine"></div>
				<div class="addbtn">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="handleDialog(index, 'top')" circle></el-button>
				</div>
				<div class="addLine"></div>
			</div>
			<div>
				<el-card style="width: 30%" class="box-card custCard workLine">
					<div slot="header" class="clearfix">
						<span>{{ card.isPolicy ? '决策岗位' : '审批岗位' }}</span>
						<el-button
							v-show="!card.isPolicy"
							icon="el-icon-delete"
							style="float: right; padding: 3px 0"
							type="text"
							@click="delCard(index)"
						></el-button>
					</div>
					<div>{{ (card.orgName ? `${card.orgName}-` : '') + (card.officeName ? `${card.officeName}-` : '') + (card.jobName || '') }}</div>
					<el-button
						v-if="card.isPolicy"
						type="primary"
						size="mini"
						icon="el-icon-plus"
						@click="handleDialog(index, 'last')"
						circle
					></el-button>
				</el-card>
				<div style="text-align: center; margin: 10px">
					当该岗位空缺时：
					<el-radio-group v-model="card.approvalType">
						<el-radio :label="1">跳过该岗位</el-radio>
						<el-radio :label="2">终止审批</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div v-show="index == newCardList.length" class="addNode">
				<div class="addLine"></div>
				<div class="addbtn">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="handleDialog(index, 'bottom')" circle></el-button>
				</div>
				<div class="addLine"></div>
			</div>
		</div>
		<div class="addLine"></div>
		<div class="workLine">
			<el-card class="workLine" style="width: 20%" shadow="always">结束审批</el-card>
		</div>
		<!-- 创建弹框 -->
		<el-dialog
			width="900px"
			title="选择岗位"
			center
			:visible.sync="dialogVisible"
			:before-close="handleClose"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			append-to-body
		>
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogItems"
				@cancel="dialogVisible = false"
				@submit="addCard"
				:defaultData="dialogModel"
			>
			</formItem>
		</el-dialog>
	</div>
</template>
<script>
import formItem from '@/views/components/components-form-item/index.vue';
export default {
	components: { formItem },
	props: {
		cardList: {
			type: Array
		},
		orgList: {
			type: Array,
			default: () => []
		},
		roleList: {
			type: Array,
			default: () => []
		}
	},
	watch: {
		cardList(newVal) {
			this.newCardList = newVal;
		},
		orgList(newVal) {
			this.orgItems = newVal;
		},
		roleList(newVal) {
			this.roleItems = newVal;
		}
	},
	data() {
		return {
			dialogVisible: false,
			form: {
				userName: ''
			},
			rules: {
				userName: [{ required: true, message: '请选择负责人', trigger: 'change' }]
			},
			newCardList: [],
			cardIndex: '',
			position: '',
			staffList: [],
			keyWork: '',
			totalNum: 0,
			pageNo: 1,
			selectList: [],
			loading: false,
			dialogItems: [
				{
					label: '岗位',
					key: 'jobId',
					type: 'select',
					defaultFirstOption: true,
					async: () =>
						this.$axios.post('/v1/profession/job/list', { request: {} }).then((res) =>
							res.list.map(({ id, jobName, officeName, orgName }) => ({
								dictId: id,
								dictName: `${orgName ? `${orgName}-` : ''}${officeName ? `${officeName}-` : ''}${jobName || ''}`,
								jobName,
								officeName,
								orgName
							}))
						),
					span: 10,
					change: async (formData, _, formItem) => {
						this.$set(formData, 'userId', '');
						if (formData.jobId) {
							formData.jobName = _.jobName;
							formData.officeName = _.officeName;
							formData.orgName = _.orgName;
							const res = await this.getUserListByJobId(formData.jobId);

							if (res?.length >= 1) {
								this.$set(formItem, 1, {
									...formItem[1],
									label: ' ',
									options: res?.map(({ id, name }) => ({ dictId: id, dictName: name })),
									rules: [{ required: true, message: '请选择', trigger: 'change' }]
								});
								if (res?.length === 1) {
									formData.userId = res?.[0]?.id;
									formData.userName = res?.[0]?.name;
								}
							} else {
								this.$set(formItem, 1, { ...formItem[1], options: [], label: '-', rules: [{ required: false }] });
							}
						} else {
							formData.jobName = '';
							this.$set(formItem, 1, { ...formItem[1], options: [], label: '-', rules: [{ required: false }] });
						}
					},
					rules: { required: true, message: '请输入', trigger: 'blur' }
				},
				{
					label: '-',
					labelWidth: '45px',
					labelPosition: 'center',
					key: 'userId',
					type: 'select',
					span: 14
				}
			],
			dialogModel: {},
			orgItems: [],
			roleItems: []
		};
	},
	mounted() {},
	methods: {
		// openDialog() {
		// 	this.$nextTick(() => {
		// 		this.$refs.multipleTable.clearSelection();
		// 	});
		// 	this.pageNo = 1;
		// 	this.keyWork = '';
		// 	this.getStaffList();
		// },
		getUserListByJobId(jobId) {
			return this.$axios
				.post('/v1/profession/findStaffListByJobId', {
					request: {
						jobId
					}
				})
				.then((res) => res);
		},
		query() {
			this.getStaffList();
		},
		getRowKey(row) {
			return row.staffId;
		},
		handleCurrentChange(val) {
			this.pageNo = val;
			this.getStaffList();
		},
		// 获取员工列表
		getStaffList() {
			this.loading = true;
			this.$axios
				.post('/v1/org/department/staff/list', {
					request: {
						pageNo: this.pageNo,
						pageSize: 10,
						orgId: '',
						keyWork: this.keyWork
					},
					sign: ''
				})
				.then((res) => {
					this.totalNum = Number(res.totalNum);
					this.staffList = res.list;
					this.loading = false;
				});
		},
		// 表格选择
		handleSelectChange(row) {
			this.selectList = [];
			this.selectList.push({ userId: row.staffId, userName: row.staffName });
		},
		cancle() {
			this.dialogVisible = false;
		},
		handleClose() {
			this.dialogVisible = false;
		},
		handleDialog(index, position) {
			this.dialogVisible = true;
			this.cardIndex = index;
			this.position = position;
		},
		addCard(form) {
			form.validate((valid) => {
				if (valid) {
					this.newCardList = [...this.cardList];
					if (this.dialogModel.jobId) {
						if (this.position === 'top') {
							this.newCardList.splice(this.cardIndex, 0, { ...this.dialogModel, approvalType: 1 });
						} else if (this.position === 'last') {
							// 决策岗位 isPolicy 是否是决策环节：0否,1是
							this.newCardList.splice(this.cardIndex, 1, { ...this.dialogModel, approvalType: 2, isPolicy: 1 });
						} else {
							this.$set(this.newCardList, this.cardIndex + 1, { ...this.dialogModel, approvalType: 1 });
						}
						this.$emit('updateList', this.newCardList);
						this.dialogModel = {};
						this.dialogVisible = false;
					}
				}
			});
		},
		delCard(index) {
			this.newCardList = this.newCardList.splice(index, 1);
		}
	}
};
</script>
<style lang="scss" scoped>
.workLine {
	text-align: center;
	margin: 0 auto;
}
.addLine {
	width: 1px;
	height: 30px;
	margin: 0 auto;
	background: #d8d8d8;
}
.addbtn {
	text-align: center;
}
</style>
<style lang="scss">
.custCard {
	.el-card__header {
		background: #e7f0fd;
		color: #484848;
		text-align: center;
	}
}
.addbtn {
	.el-button--primary {
		background: #fff;
		border: 1px solid #498df0;
		color: #498df0;
	}
}
</style>
