<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="组织展示" name="1" v-if="permissionControl('OrganizeView')">
				<organizing ref="orgpane1" :activeTab="activeName" pageName="OrganizeView" v-if="activeName === '1'" />
			</el-tab-pane>
			<el-tab-pane label="组织设置" name="2" v-if="permissionControl('OrganizeSet')">
				<organizing ref="orgpane2" :activeTab="activeName" pageName="OrganizeSet" v-if="activeName === '2'" />
			</el-tab-pane>
			<el-tab-pane label="组织审批" name="3" v-if="permissionControl('OrganizeApproval')">
				<organizing ref="orgpane3" :activeTab="activeName" pageName="OrganizeApproval" v-if="activeName === '3'" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import organizing from '../list.vue';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'organizationalList',
	components: { organizing },
	computed: {},
	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('orgInxData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'OrganizeView' },
			{ name: '2', key: 'OrganizeSet' },
			{ name: '3', key: 'OrganizeApproval' }
		]);
	},

	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'orgInxData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`orgpane${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>
