<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="viewWrapBox">
				<div class="stepWrap">
					<div>
						<div>
							<div class="titlexIcon">基本信息</div>
						</div>
						<div class="content">
							<div v-for="(item, index) in step1Data.basicPropertiesList" :key="index" style="margin: 15px 0">
								<span class="leftSpan">{{ item.approvalPropertyName }}</span>
								<el-divider direction="vertical"></el-divider>
								<span style="color: #c0c4cc">{{ item.approvalPropertyTip }}</span>
							</div>
						</div>
						<el-divider></el-divider>
					</div>
					<div>
						<div>
							<div class="titlexIcon">申请类型</div>
						</div>
						<div class="content">
							<div style="margin-bottom: 10px">
								<span class="leftSpan">申请类型</span>
								<el-divider direction="vertical"></el-divider>
								<span style="color: #c0c4cc">{{ approvalName }}</span>
							</div>
						</div>
						<el-divider></el-divider>
					</div>
					<div>
						<div>
							<div class="titlexIcon">申请详情</div>
						</div>
						<div class="content">
							<div style="text-align: right">
								<el-button size="mini" icon="el-icon-plus" @click="openCenterModal('add')" type="primary">添加字段</el-button>
							</div>
							<div
								v-for="(item, index) in step1Data.applyPropertiesList"
								:key="index"
								style="margin: 20px 0; display: flex; align-items: center"
							>
								<div class="prepertiy" v-if="item.approvalPropertyType != 5 && item.approvalPropertyType != 6">
									<span class="leftSpan">{{ item.approvalPropertyName }}</span>
									<el-divider direction="vertical"></el-divider>
									<span style="color: #c0c4cc">{{ item.approvalPropertyTip }}</span>
								</div>
								<div class="prepertiy" v-if="item.approvalPropertyType == 5">
									<span class="leftSpan">{{ item.approvalPropertyName }}</span>
									<el-divider direction="vertical"></el-divider>
									<div class="preBox">
										<el-radio v-for="(item, index) in item.approvalPropertyDefaultValue" :key="index" disabled label="禁用">{{
											item.value
										}}</el-radio>
									</div>
								</div>
								<div class="prepertiy" v-if="item.approvalPropertyType == 6">
									<span class="leftSpan">{{ item.approvalPropertyName }}</span>
									<el-divider direction="vertical"></el-divider>
									<div class="preBox">
										<el-checkbox v-for="(item, index) in item.approvalPropertyDefaultValue" :key="index" disabled label="禁用">{{
											item.value
										}}</el-checkbox>
									</div>
								</div>
								<div v-if="item.fieldType == 0">
									<el-tag>系统字段</el-tag>
								</div>
								<div class="prebtn" v-if="item.fieldType == 1">
									<el-button type="text" @click="handleEdit(item, index)">编辑</el-button>
									<el-button type="text" style="color: red" @click="delItem(index)">删除</el-button>
								</div>
							</div>
						</div>
					</div>
					<el-dialog :title="centerTitle" :visible.sync="centerDialogVisible" width="40%" center append-to-body>
						<div style="margin-bottom: 15px">
							<el-alert
								title="单行文本可输入20个字;多行文本可输入200个字符;数值范围在:0-10000000之间"
								type="info"
								show-icon
								:closable="false"
							>
							</el-alert>
						</div>
						<el-form :model="fieldForm" ref="fieldForm" label-width="100px" :rules="fieldRules" class="demo-ruleForm">
							<el-form-item label="字段类型" prop="region">
								<el-select style="width: 100%" v-model="fieldForm.approvalPropertyType" placeholder="请选择字段类型">
									<el-option
										v-for="(option, index) in propertyType"
										:key="index"
										:label="option.dictName"
										:value="option.dictId"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="字段名称" prop="approvalPropertyName">
								<el-input
									v-model="fieldForm.approvalPropertyName"
									maxlength="8"
									placeholder="1-8个字符，不能与现有字段名称重复"
								></el-input>
							</el-form-item>
							<el-form-item label="字段必填" prop="approvalPropertyRequired" style="margin: -8px 0 14px">
								<el-switch :active-value="1" :inactive-value="0" v-model="fieldForm.approvalPropertyRequired"> </el-switch>
							</el-form-item>
							<el-form-item
								v-show="
									fieldForm.approvalPropertyType == '1' ||
									(fieldForm.approvalPropertyType != '5' &&
										fieldForm.approvalPropertyType != '6' &&
										fieldForm.approvalPropertyType != '7')
								"
								prop="approvalPropertyTip"
								label="提示文案"
							>
								<el-input v-model="fieldForm.approvalPropertyTip" maxlength="15" placeholder="0-15个字符"></el-input>
							</el-form-item>
							<el-form-item
								label="字段选项"
								v-show="
									fieldForm.approvalPropertyType == '5' ||
									fieldForm.approvalPropertyType == '6' ||
									fieldForm.approvalPropertyType == '10'
								"
							>
								<div v-for="(item, index) in optiongs" :key="index">
									<el-input style="width: 80%; margin-bottom: 10px" v-model="item.value"></el-input>
									<el-button
										v-show="optiongs.length > 1"
										style="margin-left: 10px"
										type="text"
										icon="el-icon-top"
										@click.prevent="setToTop(index)"
									></el-button>
									<el-button
										v-show="optiongs.length > 1"
										style="margin-left: 10px"
										type="text"
										icon="el-icon-bottom"
										@click.prevent="setToBottom(index)"
									></el-button>
									<el-button
										style="color: red; margin-left: 10px"
										type="text"
										icon="el-icon-delete"
										@click.prevent="removeOption(item)"
									></el-button>
								</div>
								<el-button type="text" icon="el-icon-plus" @click.prevent="addOption">添加字段选项</el-button>
							</el-form-item>
							<el-form-item v-show="fieldForm.approvalPropertyType == '8'" label="单位" prop="approvalPropertyDefaultValue">
								<el-input v-model="fieldForm.approvalPropertyDefaultValue" placeholder="1-4个字符"></el-input>
							</el-form-item>
						</el-form>
						<span slot="footer" class="dialog-footer">
							<el-button @click="handleClose">取 消</el-button>
							<el-button type="primary" @click="submit">确 定</el-button>
						</span>
					</el-dialog>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as api from '@/api/audit';
// import preview from './preview';
import { isArray } from 'util';
import { Loading } from 'element-ui';
export default {
	components: {
		// preview
	},
	props: { approvalInfoId: { type: Number }, approvalName: { type: String } },
	data() {
		return {
			collActive: '1',
			centerTitle: '',
			approvalId: '',
			step1Data: {
				basicPropertiesList: [],
				applyPropertiesList: []
			},
			centerDialogVisible: false,
			fieldForm: {
				approvalPropertyPosition: '1',
				approvalPropertyName: '',
				approvalPropertyType: '1',
				approvalPropertyTip: '',
				approvalPropertyRequired: 0,
				approvalPropertyDefaultValue: '',
				fieldType: '1'
			},
			optiongs: [{ id: '', value: '' }],
			propertyType: [],
			fieldRules: {
				approvalPropertyName: [{ required: true, message: '请输入字段名称', trigger: 'blur' }]
			},
			approvalPropertiesDtoList: [],
			cardList: [],
			loading: false,
			editIndex: ''
		};
	},
	watch: {
		step1Data: {
			deep: true,
			handler(val) {
				this.$emit('updateInfo', val);
			}
		},
		approvalInfoId(val) {
			if (val) {
				this.getProCon();
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.propertyType = this.$store.state.app.dict.filter((item) => item.groupId == 'propertyType');
			// console.log(this.approvalInfoId, 'this.approvalInfoId');
			if (this.approvalInfoId) {
				this.getProCon();
			}
		});
	},
	methods: {
		getProCon() {
			const loading = Loading.service({ target: '.stepWrap' });

			api.getProCon({ request: { approvalInfoId: this.approvalInfoId } }).then((res) => {
				loading.close();
				this.step1Data = res;
				for (const data of this.step1Data.applyPropertiesList) {
					if (data.approvalPropertyType == '5' || data.approvalPropertyType == '6') {
						data.approvalPropertyDefaultValue = JSON.parse(data.approvalPropertyDefaultValue);
					}
				}
			});
		},
		openCenterModal(type) {
			if (type == 'add') {
				this.centerTitle = '添加字段';
				// 重置
				this.fieldForm = {
					approvalPropertyPosition: '1',
					approvalPropertyName: '',
					approvalPropertyType: '1',
					approvalPropertyTip: '',
					approvalPropertyRequired: 0,
					approvalPropertyDefaultValue: '',
					fieldType: '1'
				};
			}
			this.optiongs = [];
			this.centerDialogVisible = true;
		},
		addOption() {
			this.optiongs.push({
				id: '',
				value: ''
			});
		},
		removeOption(item) {
			const index = this.optiongs.indexOf(item);

			if (index !== -1) {
				this.optiongs.splice(index, 1);
			}
		},
		setToTop(index) {
			if (index == 0) {
				return;
			}
			const tempOption = this.optiongs[index - 1];

			this.$set(this.optiongs, index - 1, this.optiongs[index]);
			this.$set(this.optiongs, index, tempOption);
		},
		setToBottom(index) {
			if (index == this.optiongs.length - 1) {
				return;
			}
			const tempOption = this.optiongs[index + 1];

			this.$set(this.optiongs, index + 1, this.optiongs[index]);
			this.$set(this.optiongs, index, tempOption);
		},
		handleClose() {
			this.$refs['fieldForm'].clearValidate();
			this.$refs['fieldForm'].resetFields();
			this.centerDialogVisible = false;
		},
		submit() {
			this.$refs['fieldForm'].validate((valid) => {
				if (valid) {
					if (
						this.fieldForm.approvalPropertyType == '5' ||
						this.fieldForm.approvalPropertyType == '6' ||
						this.fieldForm.approvalPropertyType == '10'
					) {
						if (this.optiongs.length > 0) {
							for (const option of this.optiongs) {
								option.id = option.value;
							}
							this.fieldForm.approvalPropertyDefaultValue = JSON.stringify(this.optiongs);
						} else {
							this.$message.error('字段选项不能为空');
							return;
						}
					}
					// 编辑
					if (this.fieldForm.id) {
						this.step1Data.applyPropertiesList[this.editIndex] = this.fieldForm;
					} else {
						// 新增
						// eslint-disable-next-line no-lonely-if
						if (this.centerTitle == '编辑字段') {
							// 未保存情况下编辑
							this.step1Data.applyPropertiesList[this.editIndex] = this.fieldForm;
						} else {
							this.step1Data.applyPropertiesList.push({ ...this.fieldForm });
						}
					}
					for (const data of this.step1Data.applyPropertiesList) {
						if (data.approvalPropertyType == '5' || data.approvalPropertyType == '6' || data.approvalPropertyType == '10') {
							if (!isArray(data.approvalPropertyDefaultValue) && data.approvalPropertyDefaultValue) {
								data.approvalPropertyDefaultValue = JSON.parse(data.approvalPropertyDefaultValue);
							}
						}
					}
					this.$refs['fieldForm'].clearValidate();
					this.optiongs = [];
					this.centerDialogVisible = false;
				}
			});
		},
		handleEdit(item, index) {
			this.fieldForm = { ...item };
			if (
				this.fieldForm.approvalPropertyType == '5' ||
				this.fieldForm.approvalPropertyType == '6' ||
				this.fieldForm.approvalPropertyType == '10'
			) {
				if (!isArray(this.fieldForm.approvalPropertyDefaultValue)) {
					this.optiongs = JSON.parse(this.fieldForm.approvalPropertyDefaultValue);
				} else {
					this.optiongs = [...this.fieldForm.approvalPropertyDefaultValue];
				}
				this.fieldForm.approvalPropertyDefaultValue = JSON.stringify(this.fieldForm.approvalPropertyDefaultValue);
			}
			this.fieldForm.approvalPropertyType = String(this.fieldForm.approvalPropertyType);
			this.centerTitle = '编辑字段';
			this.centerDialogVisible = true;
			this.editIndex = index;
		},
		delItem(index) {
			this.step1Data.applyPropertiesList.splice(index, 1);
		}
	}
};
</script>
<style lang="scss" scoped>
.assessBox .app-container .viewWrapBox .level_box_now {
	font-weight: bold;
}
.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.titlebox {
			height: 55px;
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewWrapBox {
			background: #ffffff;
			min-height: 300px;
			padding: 50px;
		}
	}
}
p {
	margin-top: 0px;
}
.step {
	background: #fff;
	height: 100px;
	margin: 20px;
}
.stepWrap {
	margin-top: 20px;
	.content {
		padding: 10px 60px;
	}
	.titlexIcon {
		font-size: 18px;
		font-weight: bold;
		&:before {
			display: inline-block;
			width: 8px;
			height: 20px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}
.titleTip {
	height: 30px;
}
.leftSpan {
	width: 150px;
	display: inline-block;
	text-align: right;
}

.prepertiy {
	width: 92%;
}
.prebtn {
	display: inline-flex;
}
.preBox {
	width: 300px;
	display: inline-block;
	vertical-align: middle;
}
</style>
