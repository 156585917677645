<template>
	<div class="app-container organzing">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-row style="margin-bottom: 20px; margin-top: 10px" v-show="['first'].includes(activeName)">
				<div class="selectinput">
					<!-- <el-select v-model="searchType" size="small"> -->
					<!-- <el-option value="1" label="组织"></el-option> -->
					<!-- <el-option value="2" label="员工类型"></el-option> -->
					<!-- </el-select> -->
					<el-input readonly="readonly" value="组织" size="small" style="width: 80px"></el-input>
					<el-input v-model="search" size="small" placeholder="请输入组织名称" v-show="searchType === '1'"></el-input>
					<el-select
						v-model="value"
						v-show="searchType === '2'"
						filterable
						clearable
						placeholder="请选择员工类型"
						style="width: 160px"
						prefix-icon="el-icon-search"
						@change="selectChange"
						size="small"
					>
						<!-- <i slot="suffix"remote
            class="el-input__icon el-icon-date"></i>-->
						<el-option v-for="item in options" :key="item.dictId" :label="item.dictName" :value="item.dictId"></el-option>
					</el-select>
				</div>
				<el-row v-show="activeName != 'third'" class="export_box">
					<el-button type="primary" size="small" @click="addDialog" v-show="activeName === 'second' && $refs.center.tableData.length === 0"
						>创建成本中心</el-button
					>
					<el-button
						type="primary"
						size="small"
						icon="el-icon-upload2"
						@click="handleOpenImportDialog"
						v-if="permissionControlBtns(this.pageNameN, 'Import') && !isOrgView"
						>导入
					</el-button>
					<el-button
						type="primary"
						size="small"
						icon="el-icon-download"
						@click="derivedassessment"
						v-if="permissionControlBtns(this.pageNameN, 'Export')"
						>导出
					</el-button>
					<el-button
						type="primary"
						size="small"
						icon="el-icon-share"
						@click="viewType = viewType === 'map' ? 'table' : 'map'"
						v-if="permissionControlBtns(this.pageNameN, 'OrgMap')"
						>组织架构图</el-button
					>
				</el-row>
				<!-- <el-input v-model="search" :placeholder="searchType===='1'?'请输入组织名称':'请输入员工类型'"></el-input> -->
			</el-row>
			<organizational-structure
				:search="search"
				ref="structure"
				:viewType="viewType"
				:activeTab="activeTab"
				v-if="permissionControl('OrganizeView') && isOrgView"
				pageName="OrganizeView"
			></organizational-structure>
			<el-tab-pane label="组织架构" name="first" v-if="permissionControl('OrganizationalStructure') && isOrgSet">
				<organizational-structure
					:search="search"
					ref="structure"
					:viewType="viewType"
					:activeTab="activeTab"
					v-if="activeName === 'first'"
					pageName="OrganizationalStructure"
				></organizational-structure>
			</el-tab-pane>
			<!-- <el-tab-pane label="展示配置" name="viewConfig" v-if="permissionControl(['/v1/org/list']) && isOrgView">
				<organizational-config ref="config" v-if="activeName === 'viewConfig'" />
			</el-tab-pane> -->

			<el-tab-pane label="人力计划" name="plan" v-if="permissionControl('ManpowerPlanning') && isOrgSet">
				<manpowerPlanning ref="plan" :viewType="viewType" v-if="activeName === 'plan'" pageName="ManpowerPlanning" />
			</el-tab-pane>
			<el-tab-pane label="组织职能" name="duty" v-if="permissionControl('OrganizingFunction') && isOrgSet">
				<organizationalFunctions ref="duty" v-if="activeName === 'duty'" pageName="OrganizingFunction" />
			</el-tab-pane>
			<el-tab-pane label="组织负责人" name="head" v-if="permissionControl('OrganizationLeader') && isOrgSet">
				<organizationalCharge ref="head" v-if="activeName === 'head'" pageName="OrganizationLeader" />
			</el-tab-pane>
			<el-tab-pane label="组织架构调整" name="orgChange" v-if="permissionControl('OrganizationalStructureChange') && isOrgApro">
				<auditList ref="orgChange" :activeName="activeName" v-if="activeName === 'orgChange'" pageName="OrganizationalStructureChange" />
			</el-tab-pane>
			<el-tab-pane label="人力计划调整" name="planChange" v-if="permissionControl('ManpowerPlanningChange') && isOrgApro">
				<auditList ref="planChange" :activeName="activeName" v-if="activeName === 'planChange'" pageName="ManpowerPlanningChange" />
			</el-tab-pane>
			<el-tab-pane label="组织职能调整" name="dutyChange" v-if="permissionControl('OrganizingFunctionChange') && isOrgApro">
				<auditList ref="dutyChange" :activeName="activeName" v-if="activeName === 'dutyChange'" pageName="OrganizingFunctionChange" />
			</el-tab-pane>
			<el-tab-pane label="组织负责人任命" name="headChange" v-if="permissionControl('OrganizationLeaderChange') && isOrgApro">
				<auditList ref="headChange" :activeName="activeName" v-if="activeName === 'headChange'" pageName="OrganizationLeaderChange" />
			</el-tab-pane>
			<!-- <el-tab-pane label="成本中心" name="second" v-if="permissionControl('/v1/costCenter/list')"> -->
			<!-- 成本中心 -->
			<!-- <cost-center ref="center"></cost-center> -->
			<!-- </el-tab-pane> -->
			<!-- <el-tab-pane label="组织修改记录" name="third" v-if="permissionControl('/v1/org/modifRecord')">
				<revision-history></revision-history>
			</el-tab-pane> -->
		</el-tabs>

		<!-- 导入按钮的弹窗 -->
		<el-dialog width="40%" v-loading="uploading" :visible.sync="dialogImportVisible" @open="openDialog">
			<span slot="title" class="dialog-title">导入</span>
			<div class="import_dialog">
				<div class="import_dialog_btn">
					<el-upload
						class="upload-demo"
						action
						:show-file-list="false"
						:http-request="exportExcel"
						:multiple="false"
						:file-list="fileList"
						:on-exceed="handleExceed"
					>
						<el-button type="primary" icon="el-icon-upload" round>导入Excel</el-button>
					</el-upload>
				</div>
				<div class="import_dialog_desc">
					<span v-show="errShow" style="color: red" class="import_dialog_desc_download_text" @click="downLoadError"
						>*导入失败，请点击这里下载查看</span
					>
				</div>
				<div class="import_dialog_desc">
					<span class="import_dialog_desc_download_text" @click="handleDownloadTemplate">Excel模板下载</span>
					<span>（注：导入操作请务必以下载的模板为准！）</span>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import organizationalStructure from './organizational/organizationalStructure.vue';
// import organizationalConfig from './organizational/organizationalConfig.vue';
import manpowerPlanning from './organizational/manpowerPlanning.vue';
import organizationalFunctions from './organizational/organizationalFunctions.vue';
import organizationalCharge from './organizational/organizationalCharge.vue';
import auditList from './organizational/approval/approval.vue';
// import revisionHistory from './revisionHistory/revisionHistory.vue';
// import costCenter from './costCenter/costCenter.vue';
import * as sysApi from '@/api/workbench';
export default {
	components: {
		auditList,
		organizationalStructure,
		// organizationalConfig,
		manpowerPlanning,
		organizationalFunctions,
		organizationalCharge
		// revisionHistory
		// costCenter
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	data() {
		return {
			fileList: [],
			dialogImportVisible: false,
			errShow: false,
			errUrl: '',
			activeName: '',
			searchType: '1',
			value: '',
			search: '',
			list: [],
			options: [],
			total: 0,
			listLoading: false,
			listQuery: {
				page: 1,
				limit: 10,
				langSelected: '',
				brandKey: ''
			},
			uploading: false,
			viewType: 'table'
		};
	},
	watch: {
		activeName(val) {
			if (val && val !== '0') {
				sessionStorage.setItem(
					`orgListData${this.activeTab}`,
					JSON.stringify({
						activeName: val
					})
				);
			}
		}
		// activeTab(val) {

		// }
	},
	computed: {
		dictData() {
			return this.$store.state.app.dict;
		},
		isOrgView() {
			return this.activeTab === '1' && this.activeName;
		},
		isOrgSet() {
			return this.activeTab === '2' && this.activeName;
		},
		isOrgApro() {
			return this.activeTab === '3' && this.activeName;
		},
		pageNameN() {
			if (this.permissionControl('OrganizationalStructure') && this.isOrgSet) {
				return 'OrganizationalStructure';
			}
			return this.pageName;
		}
	},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`orgListData${this.activeTab}`));
		const { activeName } = data || {};

		if (activeName) {
			this.activeName = activeName;
		} else {
			switch (this.activeTab) {
				case '1':
				case '2':
					this.activeName = 'first';
					break;
				case '3':
					this.activeName = 'orgChange';
					break;

				default:
					break;
			}
		}
		console.log(this.pageName, this.activeName, 'this.pageName');
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: 'first', key: 'OrganizationalStructure' },
			{ name: 'plan', key: 'ManpowerPlanning' },
			{ name: 'duty', key: 'OrganizingFunction' },
			{ name: 'head', key: 'OrganizationLeader' },
			{ name: 'orgChange', key: 'OrganizationalStructureChange' },
			{ name: 'planChange', key: 'ManpowerPlanningChange' },
			{ name: 'dutyChange', key: 'OrganizingFunctionChange' },
			{ name: 'headChange', key: 'OrganizationLeaderChange' }
		]);
		this.getTypeList();
	},

	methods: {
		getTemplate(name) {
			const dictObj = this.dictData.filter((item) => item.groupId === 'importTemplate').filter((el) => el.dictId === name);

			return dictObj[0].remark;
		},
		// 获取组织类型
		getTypeList() {
			this.$axios.get('/v1/common/dict').then((res) => {
				this.options = res.filter((item) => item.groupId === 'informal');
			});
		},
		selectChange(e) {
			//   console.log('e', this.$refs.structure);
			this.$refs.structure.getList(e);
		},
		handleOpenImportDialog() {
			if (this.activeName === 'second') {
				this.$confirm(
					'导入会覆盖掉之前导入的数据，导致已关联的员工成本中心相关信息丢失，员工成本中心相关信息需要重新录入，您确定要导入?',
					'导入提示',
					{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}
				).then(() => {
					this.dialogImportVisible = true;
				});
			} else {
				this.$confirm(
					'导入会覆盖掉之前导入的数据，导致已关联的员工组织相关信息丢失，员工组织相关信息需要重新录入，您确定要导入?',
					'导入提示',
					{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}
				).then(() => {
					this.dialogImportVisible = true;
				});
			}
		},
		// 导出
		derivedassessment() {
			// console.log('document.location.origin', document.location);
			let url = '';

			if (this.activeName === 'first') {
				url = '/v1/org/export';
			}
			if (this.activeName === 'second') {
				url = '/v1/costCenter/export';
			}
			window.open(document.location.origin + url);
		},
		openDialog() {
			this.errShow = false;
		},
		downLoadError() {
			window.location.href = this.errUrl;

			//   setTimeout(function () {
			//     this.errShow = false;
			//   }, 1000);
		},
		// 下载excel模板
		handleDownloadTemplate() {
			let url = '';

			if (this.activeName === 'first') {
				// url = document.location.origin + "/导入组织模板.xlsx";
				url = this.getTemplate('importOrg');
			}
			if (this.activeName === 'second') {
				url = this.getTemplate('importCostCenter');
				// url = document.location.origin + "/导入成本中心模板.xlsx";
			}

			window.location.href = url;
		},
		// 上传控件的控制
		exportExcel(event) {
			let url = '';

			if (this.activeName === 'first') {
				url = '/v1/org/import';
			}
			if (this.activeName === 'second') {
				url = '/v1/costCenter/import';
			}

			// let file=fileObj.file;
			if (!event.file || !event.file.name) {
				this.$message.error('文件有误');
				return;
			}
			if (
				['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].indexOf(event.file.type) === -1 &&
				!event.file.name.includes('.xl')
			) {
				this.$message.error('请上传正确Excel文件');
				return;
			}
			const params = new FormData();

			params.append('imgFile', event.file);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';
			const loading = this.$loading({
				lock: true,
				text: '导入中,请稍后...',
				customClass: 'loadClass',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			this.$axios
				.post(url, params, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					loading.close();
					if (res) {
						if (res.errorDataUrl) {
							this.errShow = true;
							this.errUrl = res.errorDataUrl;
						} else if (res._responseStatusCode === 0) {
							this.$message.success('导入成功');
							this.dialogImportVisible = false;
							if (this.activeName === 'first') {
								this.$refs.structure.getList();
								sysApi
									.queryDepartment({
										request: {}
									})
									.then((res) => {
										this.$store.commit('SET_DEPT', res);
									})
									.catch(() => {});
							}
							if (this.activeName === 'second') {
								this.$refs.center.getList();
							}
						}
						// this.$message.success("导入成功!");
						// this.dialogImportVisible = false;
						// window.location.href = res.errorDataUrl;
					}
				});
			this.fileList = [];
		},
		handleExceed() {
			this.$message.warning('请选择单个文件');
		},
		addDialog() {
			this.$refs.center.createCost();
		},
		handleClick() {}
	}
};
</script>

<style lang="scss">
.app-container {
	position: relative;
}
.organzing {
	.el-message-box__status {
		top: 20px;
	}
	.el-tabs__header {
		margin-bottom: 0;
	}
}
.export_box {
	// position: absolute;
	// right: 0px;
	// top: 25px;
	display: inline-block;
	// margin-right: 10px;
	z-index: 5;
	float: right;
}
.selectinput {
	// position: absolute;
	// right: 204px;
	// top: 24px;
	display: inline-block;
	margin-right: 30px;
	z-index: 5;
	border: 1px solid #ccc;
	.el-input__inner {
		border: none;
	}
	.el-select:first-of-type {
		width: 103px;
		.el-input__inner {
			text-align: center;
		}
	}
	> .el-input {
		width: 160px;
	}
}
.section-wrap {
	padding: 0 35px;
}
.brank-icon {
	width: 60px;
}

.import_dialog {
	width: 100%;
	// background-color:pink;
	// height: 200px;
	// padding-top: 50px;
	.import_dialog_btn {
		width: 300px;
		// height: 90px;
		text-align: center;
		// line-height: 90px;
		// background-color: rgb(64, 158, 255);
		// background-color: red;
		margin: 0 auto;
		// overflow: hidden;
		.import_dialog_btn_inner {
			// width: 400px;
			width: 300px;
			color: #fff;
			height: 100%;
			display: block;
			// background-color: red;
			// padding: 0 100px;
		}
	}
	.import_dialog_desc {
		text-align: center;
		margin-top: 15px;
		.import_dialog_desc_download_text {
			color: rgb(64, 158, 255);
			cursor: pointer;
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-dialog__header {
	text-align: center;
	.dialog-title {
		// font-family: PingFangSC-Medium;
		// font-size: 18px;
		// color: #409eff;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
	}
}
// ::v-deep.el-dialog__body {
//   border-top: 1px solid #eaedf1;
// }
</style>
