<template>
	<div class="organizationalStructure">
		<!--  :cell-class-name="cellStyle" -->
		<el-table
			:data="tableData"
			ref="treeTable"
			v-loading="loading"
			style="width: 100%; margin-bottom: 20px"
			row-key="id"
			default-expand-all
			class="tableBackground"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<template v-for="item in columns">
				<el-table-column :key="item.key" :label="item.label" :width="getWidthByKey(item.key)" :prop="item.key" v-if="item.key === 'orgName'">
					<template slot-scope="scope">
						<span style="margin-right: 30px">{{ scope.row.orgName }}</span>

						<span class="operate_row" v-if="isEdit">
							<span @click="handleAdd(scope.row)" v-if="permissionControlBtns(pageName, 'Add')">新增</span>
							<span @click="handleEdit(scope.row)" v-if="permissionControlBtns(pageName, 'Edit')">编辑</span>
							<!-- <span @click="checkStaff(scope.row)">{{ scope.row.orgPid == 0 ? '待分配员工' : '员工' }}</span> -->
							<span @click="handleMove(scope.row)" v-show="scope.row.orgPid != 0" v-if="permissionControlBtns(pageName, 'Move')"
								>移动</span
							>
							<span
								@click="handleDelete(scope.row)"
								v-show="scope.row.orgPid != 0"
								style="color: #fe1c1c"
								v-if="permissionControlBtns(pageName, 'Delete')"
								>删除</span
							>
						</span>
					</template>
				</el-table-column>
				<el-table-column
					:key="'_' + item.key"
					:label="item.label"
					:width="getWidthByKey(item.key)"
					:prop="item.key"
					v-else-if="item.key === 'orgFunction'"
				>
					<template slot-scope="scope">
						<el-popover placement="bottom-start" trigger="click" width="800" @show="() => showOrgFuncTion(scope)">
							<baseTable
								v-if="scope.$index === showIndex || popLoading"
								:dataList="viewData"
								:columns="viewColumns"
								:showPagination="false"
								:spanMethod="spanMethod"
								v-loading="popLoading"
							/>
							<div slot="reference" style="cursor: pointer; color: #409eff">{{ scope.row.orgFunction || '查看' }}</div>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column align="center" :key="'__' + item.key" :label="item.label" :width="getWidthByKey(item.key)" :prop="item.key" v-else>
				</el-table-column>
			</template>
			<!-- <el-table-column :label="item.label" :width="getWidthByKey(item.key)" :prop="item.key">
				<template slot-scope="scope" v-if="item.key === 'orgName'">
					<span style="margin-right: 30px">{{ scope.row.orgName }}</span>

					<span class="operate_row" v-if="isEdit">
						<span @click="handleAdd(scope.row)">新增</span>
						<span @click="handleEdit(scope.row)">编辑</span>
						<span @click="checkStaff(scope.row)">{{ scope.row.orgPid == 0 ? '待分配员工' : '员工' }}</span>
						<span @click="handleMove(scope.row)" v-show="scope.row.orgPid != 0">移动</span>
						<span @click="handleDelete(scope.row)" v-show="scope.row.orgPid != 0" style="color: #fe1c1c">删除</span>
					</span>
				</template>
				<template slot="header" v-if="item.key === 'employeesNumber'">
					<span>编制人数</span>
					<el-tooltip content="编制 = 在职+待入职+空缺" placement="top" popper-class="popper_tooltip_class">
						<i class="el-icon-info icon"></i>
					</el-tooltip>
				</template>
			</el-table-column> -->
			<!-- <el-table-column label="组织名称" width="400px">
				<template slot-scope="scope">
					<span style="margin-right: 30px">{{ scope.row.orgName }}</span>

					<span class="operate_row" v-if="isEdit">
						<span @click="handleAdd(scope.row)">新增</span>
						<span @click="handleEdit(scope.row)">编辑</span>
						<span @click="checkStaff(scope.row)">{{ scope.row.orgPid == 0 ? '待分配员工' : '员工' }}</span>
						<span @click="handleMove(scope.row)" v-show="scope.row.orgPid != 0">移动</span>
						<span @click="handleDelete(scope.row)" v-show="scope.row.orgPid != 0" style="color: #fe1c1c">删除</span>
					</span>
				</template>
			</el-table-column> -->
			<!-- <el-table-column label="组织编码" prop="orgCode"> </el-table-column> -->
			<!-- <el-table-column prop="employeesNumber" align="center">
				<template slot="header">
					<span>编制人数</span>
					<el-tooltip content="编制 = 在职+待入职+空缺" placement="top" popper-class="popper_tooltip_class">
						<i class="el-icon-info icon"></i>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column label="月末人数" align="center" prop="monthEndNumber"></el-table-column> -->
			<!-- <el-table-column align="center" width="">
				<template slot="header">
					<span @click="checkTree(data)" style="cursor: pointer">
						<span>架构图</span>
						<i class="el-icon-share icon"></i>
					</span>
				</template>
				<template slot-scope="scope">
					<i class="el-icon-share icon" style="cursor: pointer" @click="checkTree(scope.row)"></i>
				</template>
			</el-table-column> -->
			<!-- <el-table-column label="负责人" prop="orgPrinName" align="center" width=""> </el-table-column>
			<el-table-column label="直属人数" align="center" prop="directNumber"></el-table-column> -->
			<!-- <el-table-column align="center" width="">
				<template slot="header">
					<span>在职人数</span>
					<el-tooltip content="当前组织和子组织的总人数（正式人数/非正式人数）" placement="top" popper-class="popper_tooltip_class">
						<i class="el-icon-info icon"></i>
					</el-tooltip>
				</template>
				<template slot-scope="scope">
					<span
						>{{ scope.row.officialNumber * 1 + scope.row.informalityNumber * 1 }}({{ scope.row.informalityNumber }}/{{
							scope.row.officialNumber
						}})</span
					>
				</template>
			</el-table-column> -->
		</el-table>
		<!-- 新增/编辑 弹框 -->
		<edit-dialog
			:dialogVisible.sync="showEditDialog"
			:operateType="operateType"
			:ruleForm="editForm"
			:selectedCityArr="selectedCity"
			:theTop="top"
		></edit-dialog>

		<staff-list :dialogVisible.sync="staffDialog" :organizeParams="organizeParams" :organizeList="treeSelectData"></staff-list>

		<!-- 移动弹框 -->
		<move-dialog :dialogVisible.sync="moveDialog" :organizeList="treeSelectData" :organzieId="organzieId" :lable="title"></move-dialog>
	</div>
</template>
<script>
import axios from 'axios';
import editDialog from '../components/editDialog.vue';
import moveDialog from '../components/moveDialog.vue';
import staffList from '../components/staffList.vue';
import Sortable from 'sortablejs';
import { setUrlParams } from '@/utils/util';
import { cloneDeep } from 'loadsh';
import { Loading } from 'element-ui';
import baseTable from '@/views/components/components-base-table';

export default {
	components: {
		baseTable,
		editDialog,
		moveDialog,
		staffList
	},
	props: {
		search: {
			type: String,
			default: ''
		},
		viewType: { type: String },
		activeTab: { type: String },
		pageName: { type: String }
	},
	data() {
		return {
			top: '',
			title: '上级组织',
			loading: false,
			showEditDialog: false,
			moveDialog: false,
			staffDialog: false,
			hover: true,
			data: {},
			treeSelectData: [],
			organzieId: '',
			table: [],
			tableData: [],
			expendRows: [],
			operateType: '',
			editForm: {},
			selectedCity: [],
			organizeParams: {
				id: '',
				title: ''
			},
			viewData: [],
			viewColumns: [
				{ label: '一级职能', key: 'functionDesc1' },
				{
					label: '二级职能',
					key: 'functionsTwo',
					render: (e) => {
						if ([2, 3].includes(e.functionLevel)) {
							return <span>{e.functionDesc}</span>;
						}
						return <div></div>;
					}
				},
				{
					label: '三级职能',
					key: 'functionsThree',
					children: [
						{
							label: '计划/规划',
							key: 'plan'
						},
						{
							label: '组织/实施',
							key: 'enforce'
						},
						{
							label: '协调/配合',
							key: 'cohere'
						},
						{
							label: '指导/监控',
							key: 'monitor'
						}
					]
				}
			],
			popLoading: false,
			showIndex: null
		};
	},
	computed: {
		isOrgSet() {
			return this.pageName === 'OrganizationalStructure';
		},
		columns() {
			return [
				{ label: '组织名称', key: 'orgName' },
				{ label: '组织等级', key: 'orgLevel' },
				{ label: '组织城市', key: 'cityName' },
				!this.isOrgSet && { label: '组织负责人', key: 'orgPrinName' },
				!this.isOrgSet && { label: '计划人数(本层级)', key: 'employeesNumber' },
				!this.isOrgSet && { label: '计划人数(含下级)', key: 'employeesNumberTotal' },
				!this.isOrgSet && { label: '当前实际人数(本层级)', key: 'actualNumber' },
				!this.isOrgSet && { label: '当前实际人数(含下级)', key: 'actualNumberTotal' },
				!this.isOrgSet && { label: '组织职能', key: 'orgFunction' }
			].filter((v) => v);
		},
		tableDataList() {
			return this.pavingData(this.tableData);
		},
		isEdit() {
			return this.activeTab === '2';
		},
		getWidthByKey() {
			return (key) => {
				let w = '';

				switch (key) {
					case 'orgName':
						w = this.isEdit ? '400px' : '280px';
						break;

					default:
						break;
				}
				return w;
			};
		}
	},
	created() {
		this.getList();
		// this.findOrgPermission();
	},
	mounted() {
		if (this.isEdit) {
			this.changeTableRowSort();
		}
	},
	watch: {
		search: {
			handler(val) {
				if (val) {
					this.expendRows = [];
					this.tableData = this.searchData(this.table, val);
					this.expendRows.forEach((v) => {
						this.$refs.treeTable.toggleRowExpansion(v, true);
					});
				} else {
					this.tableData = this.table;
				}
			},
			immediate: true
		},
		viewType(newVal) {
			switch (newVal) {
				case 'map':
					this.$router.push({
						path: '/organizeMap',
						query: {
							params: setUrlParams({ isEdit: this.isEdit })
						}
					});
					break;
				case 'table':
					break;

				default:
					break;
			}
		}
	},
	methods: {
		// 权限限制
		findOrgPermission() {
			this.$axios.post('/v1/org/findOrgPermission', { request: {} }).then((res) => {
				if (res?.code) {
					return;
				}
				this.columns = res || [];
			});
		},
		cellStyle({ row, column, rowIndex, columnIndex }) {
			if (columnIndex == 0) {
				return 'org_name';
			}
			if ((row, column, rowIndex)) {
				//   console.log('');
			}
		},
		searchData(data, val) {
			const newData = [];

			data.forEach((v) => {
				if (v.children && v.children.length > 0) {
					let flag = false;

					this.expendRows.push(v);
					if (v.orgName && v.orgName.toLowerCase().match(new RegExp(val.toLowerCase()))) {
						flag = true;
						newData.push(v);
					}
					const children = this.searchData(v.children, val);
					const obj = {
						...v,
						children
					};

					if (children && children.length && !flag) {
						newData.push(obj);
					}
				} else if (v.orgName && v.orgName.toLowerCase().match(new RegExp(val.toLowerCase()))) {
					this.expendRows.push(v);
					newData.push(v);
				}
			});
			return newData;
		},
		getList(id) {
			this.loading = true;
			let isAdmin = false;
			const org = this.$store.state.app.menusPermission?.find(({ component }) => component === 'OrganizationalManagement');

			if (org) {
				isAdmin = true;
			}
			if (isAdmin) {
				axios.post('/v1/org/list', { request: { staffType: id || '' } }).then((res) => {
					if (res[0]) {
						this.data = res[0];
						this.treeSelectData = [res[0]];
						this.tableData = this.table = [res[0]];
						this.$store.commit('SET_DEPT', res);
					}
					this.loading = false;
				});
			} else {
				axios.post('/v1/org/list/lower', { request: { orgId: this.$store.state.app.orgId || '' } }).then((res) => {
					if (res[0]) {
						this.data = res[0];
						this.treeSelectData = [res[0]];
						this.tableData = this.table = [res[0]];
						this.$store.commit('SET_DEPT', res);
					}
					this.loading = false;
				});
			}
		},
		// 查看架构图
		checkTree(row) {
			this.$router.push({
				path: '/organizeMap',
				query: {
					params: setUrlParams(row)
				}
			});
		},

		// 新增
		handleAdd(row) {
			this.top = '';
			this.selectedCity = [];
			this.operateType = '添加组织';
			this.editForm = {
				orgPid: '',
				orgType: '',
				orgPrinId: '',
				orgName: '',
				orgCode: '',
				orgPrinName: '',
				provinceCode: '',
				cityCode: '',
				remark: ''
			};
			if (row) {
				this.editForm.orgPid = row.id;
			}
			this.showEditDialog = true;
		},
		// 编辑
		handleEdit(row) {
			// this.$refs.editDialog.handleClose();
			this.selectedCity = [];

			this.operateType = '编辑组织';
			if (row) {
				this.top = row.orgPid == 0 ? 'top' : '';
				this.organzieId = row.id;

				this.selectedCity.push(String(row.provinceCode || ''), String(row.cityCode || ''));

				this.editForm = {
					id: row.id,
					orgPid: row.orgPid,
					orgType: row.orgType,
					orgPrinId: row.orgPrinId,
					orgName: row.orgName,
					orgCode: row.orgCode,
					orgPrinName: row.orgPrinName,
					provinceCode: row.provinceCode,
					cityCode: row.cityCode,
					employeesNumber: row.employeesNumber,
					monthEndNumber: row.monthEndNumber,
					remark: row.remark
				};
			}
			this.showEditDialog = true;
		},
		// 移动
		handleMove(row) {
			this.organzieId = row.id;
			this.moveDialog = true;
		},
		// 员工
		checkStaff(row) {
			if (row) {
				this.organizeParams.title = row.orgName;
				this.organizeParams.id = row.id;
			}
			this.staffDialog = true;
		},
		// 删除
		handleDelete(row) {
			this.$confirm('是否确定删除该组织?', `${row.orgName},已绑定人数:${Number(row.officialNumber) + Number(row.informalityNumber)}`, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				axios
					.post('/v1/org/delete', {
						request: { id: Number(row.id) },
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('删除成功!');
							this.getList();
						} else {
							// this.$message.error(res.message)
						}
					});
			});
		},
		// 将嵌套数据平铺
		pavingData(list) {
			if (!list?.length) {
				return [];
			}
			let arr = [];

			if (list && list.length) {
				list.forEach((data) => {
					const { children } = data;

					if (children?.length) {
						arr.push({ ...data });
						arr = arr.concat(this.pavingData(children));
					} else {
						arr.push({ ...data, children });
					}
				});
			}
			return arr;
		},
		// 获取所有子节点 通过id
		getAllChildIds(id, allList) {
			const childs = allList.filter(({ orgPid }) => id === orgPid);

			let arr = [];

			if (childs?.length) {
				arr = arr.concat(childs);

				childs.forEach(({ id }) => {
					arr = [...arr, ...this.getAllChildIds(id, allList)];
				});
			}

			return arr;
		},
		// 将平铺数据转嵌套
		nestData(list) {
			if (!list?.length) {
				return [];
			}
			const arr = [];

			if (list?.length) {
				let alreadyIds = [];

				list.forEach((data) => {
					if (!alreadyIds.includes(data.id)) {
						const allChild = this.getAllChildIds(data.id, this.newDataList);

						if (allChild?.length) {
							alreadyIds = alreadyIds.concat(allChild.map(({ id }) => id));
							arr.push({ ...data, children: this.nestData(allChild) });
						} else {
							arr.push({ ...data, children: [] });
						}
					}
				});
			}
			return arr;
		},
		// table行移动
		changeTableRowSort() {
			this.$nextTick(() => {
				const _this = this;

				this.drop = Sortable.create(document.querySelector('.el-table .el-table__body-wrapper tbody'), {
					// handle:'.expanded',
					animation: 180,
					delay: 0,

					onStart(e) {
						const list = cloneDeep(_this.tableDataList);
						const dragRow = list[e.oldIndex];

						// 拖动目标有子集时关闭展开状态
						if (dragRow?.children?.length) {
							_this.$refs.treeTable.toggleRowExpansion(dragRow, false);
						}
					},
					onEnd(evt) {
						if (evt.newIndex === 0 || evt.oldIndex === 0 || evt.oldIndex === evt.newIndex) {
							if (evt.oldIndex === evt.newIndex) {
								return;
							}
							const _orgData = cloneDeep(_this.tableData);

							_this.tableData = [];
							setTimeout(() => {
								_this.tableData = _orgData;
							}, 0);
							return;
						}
						// 平铺数据
						const list = cloneDeep(_this.tableDataList);
						const targetRow = cloneDeep(list[evt.newIndex]);
						const oldRow = cloneDeep(list[evt.oldIndex]);

						list.splice(evt.oldIndex, 1);
						list.splice(evt.newIndex, 0, { ...oldRow, orgPid: targetRow.orgPid });
						_this.newDataList = list;
						const loading = Loading.service({ target: '.organizationalStructure' });

						_this
							.submitMove({
								id: oldRow.id,
								pid: targetRow.orgPid,
								orgListResponses: list
									.filter(({ orgPid }) => orgPid === targetRow.orgPid)
									.map((val, inx) => ({ ...val, sortNo: inx + 1 }))
							})
							.then((res) => {
								_this.tableData = [];
								loading.close();
								if (res) {
									setTimeout(() => {
										_this.tableData = _this.nestData(list);
									}, 0);
								} else {
									const _orgData = [..._this.tableData];

									setTimeout(() => {
										_this.tableData = _orgData;
									}, 0);
								}
							})
							.catch(() => {
								const _orgData = [..._this.tableData];

								_this.tableData = [];
								setTimeout(() => {
									_this.tableData = _orgData;
								}, 0);
								loading.close();
							});
					}
				});
			});
		},
		// 移动提交
		submitMove(params) {
			return this.$axios.post('/v1/org/move', { request: params }).then((res) => {
				if (res?.code === 0) {
					this.$message.success('移动成功');
					return true;
				}
				return false;
			});
		},
		// 查询组织职能
		getOrgFunction(e) {
			return this.$axios
				.post('/v1/org/findOrgFunctionByOrgId', { request: { orgId: e.orgId } })
				.then((res) => {
					if (res.code) {
						return;
					}

					return res;
				})
				.catch(() => {});
		},
		// eslint-disable-next-line no-unused-vars
		spanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				if (rowIndex === 0 && columnIndex === 0) {
					return [this.viewData.length, 1];
				}
				return [0, 0];
			}
		},
		async showOrgFuncTion({ row: data, $index }) {
			this.showIndex = $index;
			this.popLoading = true;
			const res = await this.getViewDetail(data.id);

			this.popLoading = false;
			this.viewData =
				res.orgFunctions?.map((val) => {
					const [plan, enforce, cohere, monitor] = [
						val.orgFunctions[0]?.functionDesc,
						val.orgFunctions[1]?.functionDesc,
						val.orgFunctions[2]?.functionDesc,
						val.orgFunctions[3]?.functionDesc
					];

					return { ...val, cohere, enforce, monitor, plan, functionDesc1: res.functionDesc };
				}) || [];
		},
		// 查询组织职能详情
		getViewDetail(e) {
			return this.$axios
				.post('/v1/org/findOrgFunctionByOrgId', { request: { orgId: e } })
				.then((res) => {
					if (res.code) {
						return;
					}

					return res;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss">
.organizationalStructure {
	.el-message-box__status {
		top: 20%;
	}
}
</style>
<style lang="scss" scoped>
.container {
	min-height: 600px;
}
.icon {
	color: #4a8df0;
}
.operate_row {
	color: #0066ff;
	font-size: 13px;
	span {
		margin-right: 8px;
		cursor: pointer;
	}
}
.operate_row {
	display: none;
}
tr:hover {
	.operate_row {
		display: inline;
	}
}
</style>
<style>
.org_name {
	padding-left: 15px !important;
}
/* .popper_tooltip_class.el-tooltip__popper.is-light {
  border: 1px solid #0066ff;
  color: #333;
  background-color: #fff;
} */
</style>
