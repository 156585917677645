<template>
	<div class="container" style="padding: 25px">
		<el-dialog
			:title="`${auditForm.approvalName || ''}审批设置`"
			:close-on-click-modal="false"
			:visible.sync="dialogFormVisible"
			center
			@close="cancel"
			@opened="openedDialog"
		>
			<div class="formClass">
				<el-steps style="padding-bottom: 25px" align-center process-status="finish " :active="active" v-if="dialogFormVisible">
					<el-step title="基本信息"></el-step>
					<el-step title="审批表单" v-if="haveForm"></el-step>
					<el-step title="审批流"></el-step>
				</el-steps>
				<el-form :model="auditForm" ref="auditForm" :rules="rules" v-show="active === 0">
					<!-- <el-form-item label="审批名称" prop="approvalName" :label-width="formLabelWidth">
					<el-input
						v-model="auditForm.approvalName"
						style="width: 90%"
						placeholder="1-20个字，不能与现有审批名称重复"
						maxlength="20"
						autocomplete="off"
					></el-input>
				</el-form-item> -->
					<el-form-item label="发起人" prop="sponsor" :label-width="formLabelWidth" style="margin-bottom: 10px">
						<el-checkbox
							label="部门负责人可发起"
							v-model="auditForm.sponsorType"
							:true-label="1"
							:false-label="0"
							name="type"
						></el-checkbox>
						<el-tooltip class="item" effect="dark" content="所选部门范围中，只有部门负责人可发起" placement="right">
							<i class="el-icon-question" style="color: #409eff"></i>
						</el-tooltip>
						<div>
							<span v-show="auditForm.orgList.length > 0">部门：</span>
							<el-tag
								style="margin-right: 10px"
								v-for="(tag, index) in auditForm.orgList"
								:key="tag.id"
								closable
								@close="handleOrgClose(tag, index)"
								type="primary"
								>{{ tag.orgName }}</el-tag
							>
						</div>
						<div>
							<span v-show="auditForm.userList.length > 0">员工：</span>
							<el-tag
								style="margin-right: 10px"
								v-for="(tag, tagiIndex) in auditForm.userList"
								:key="tagiIndex"
								closable
								@close="handleUserClose(tag, tagiIndex)"
								type="primary"
								>{{ tag.staffName || tag.name }}</el-tag
							>
						</div>
						<div v-if="auditForm.roleList.length > 0">
							<span>角色：</span>
							<el-tag
								style="margin-right: 10px"
								v-for="(tag, tagiIndex) in auditForm.roleList"
								:key="tagiIndex"
								closable
								@close="handleRoleClose(tag, tagiIndex)"
								type="primary"
								>{{ tag.name }}</el-tag
							>
						</div>
						<depts
							@updateTag="updateTag"
							:showStaff="true"
							:checkStrictly="true"
							:setChecks="orgIds"
							:userList="auditForm.userList"
							@updateStaffTag="updateStaffTag"
						></depts>
						<el-popover placement="bottom" width="200" trigger="click">
							<el-tree
								:data="roleOptions"
								show-checkbox
								node-key="id"
								:check-strictly="true"
								ref="roleOptions"
								:props="{ label: 'name' }"
								@check="checkRole"
							></el-tree>
							<el-button size="mini" icon="el-icon-plus" slot="reference" type="text">添加角色</el-button>
						</el-popover>
					</el-form-item>
					<el-form-item label="审批流权限" :label-width="formLabelWidth">
						<el-radio-group v-model="auditForm.modifyStatus">
							<el-radio label="0">禁止编辑</el-radio>
							<el-radio label="1">编辑</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="审批意见" :label-width="formLabelWidth" prop="opinionStatus">
						<el-radio-group v-model="auditForm.opinionStatus">
							<el-radio :label="0">无需填写“审批意见”</el-radio>
							<el-radio :label="2">“审批意见”非必填</el-radio>
							<el-radio :label="1">“审批意见”为必填项</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="审批说明" :label-width="formLabelWidth" prop="approvalDescription">
						<el-input
							style="width: 90%"
							type="textarea"
							maxlength="100"
							show-word-limit
							v-model="auditForm.approvalDescription"
							class="input_textarea"
						></el-input>
					</el-form-item>
				</el-form>
				<!-- 审批表单 -->
				<approvalForm v-if="active == 1" @updateInfo="updateInfo" :approvalInfoId="auditForm.id" :approvalName="auditForm.approvalName" />
				<!-- 审批人 -->
				<approvalFlow
					@updateList="updateList"
					v-show="active == 2"
					:approvalInfoId="auditForm.id"
					:orgList="auditForm.orgList"
					:roleList="auditForm.roleList"
					:cardList="cardList"
					style="margin-bottom: 40px"
				></approvalFlow>
			</div>
			<div slot="footer" style="text-align: center" class="dialog-footer">
				<el-button style="width: 98px" @click="closeModal">{{ active === 1 || active === 2 ? '上一步' : '取 消' }}</el-button>
				<el-button type="primary" @click="saveConfig" :loading="loading">{{ active === 2 ? '提 交' : '下一步' }}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import * as api from '@/api/audit';
import depts from '@/components/departs';
import approvalFlow from './approvalFlow';
import approvalForm from './approvalForm';
import { Loading } from 'element-ui';
import { isArray } from 'util';

export default {
	name: 'approvalSet',
	components: {
		depts,
		approvalFlow,
		approvalForm
	},
	props: {
		visible: { type: Boolean, default: false },
		subType: { type: String || Number },
		activeType: { type: Number },
		approvalInfoId: { type: Number },
		haveForm: { type: Boolean, default: false }
	},
	watch: {
		visible(val) {
			this.dialogFormVisible = val;
		},
		active() {
			if (this.active === 2) {
				if (this.approvalInfoId) {
					this.auditForm.id = this.approvalInfoId;
				}
				if (this.auditForm.id) {
					this.getProcessDetail();
				}
			}
		}
	},
	computed: {},
	data() {
		const check = (rule, value, callback) => {
			if (this.auditForm.orgList.length == 0 && this.auditForm.userList.length == 0 && this.auditForm.roleList.length == 0) {
				return callback(new Error('请选择部门,人员或者角色'));
			}
			callback();
		};

		return {
			value: true,
			dialogFormVisible: false,
			orgIds: [],
			roleOptions: [],
			auditForm: {
				approvalDescription: '',
				approvalInitStatus: '',
				approvalName: '',
				approvalSwitchStatus: '',
				approvalType: '',
				id: '',
				initStep: '',
				opinionStatus: '',
				orgList: [],
				sponsorType: '',
				// subType: '7',
				userList: [],
				roleList: [],
				power: '0',
				modifyStatus: '0'
			},
			formLabelWidth: '120px',
			rules: {
				approvalName: [{ required: true, message: '请输入审批名称', trigger: 'blur' }],
				// subType: [{ required: true, message: '请选择类型', trigger: 'change' }],
				sponsor: [{ validator: check, trigger: 'blur' }],
				opinionStatus: [{ required: true, message: '请选择', trigger: 'blur' }]
			},
			boxList: [],
			cardList: [],
			active: 0,
			loading: false,
			formList: {}
			// subTypeList: []
		};
	},
	mounted() {
		this.getRoleOptions();
		this.$nextTick(() => {
			// this.subTypeList = this.$store.state.app.dict.filter(function (item) {
			//   return item.groupId == "subType";
			// })
		});
		// this.getApprovalAll();
	},
	methods: {
		getProcessDetail() {
			api.getProcessDetail({ request: { approvalInfoId: this.auditForm.id } }).then((res) => {
				const policylist = res.filter(({ isPolicy }) => isPolicy) || [];

				if (policylist.length) {
					this.cardList = res;
				} else {
					this.cardList = [...res, { isPolicy: 1, approvalType: 2 }];
				}
			});
		},
		checkRole(data, { checkedNodes }) {
			this.auditForm.roleList = checkedNodes;
			this.$refs['auditForm'].clearValidate('sponsor');
		},
		getRoleOptions() {
			api.getRoleOptions().then((res) => {
				if (res && res.length > 0) {
					this.roleOptions = res.map((v) => ({
						id: v.roleId,
						name: v.roleName
					}));
				}
			});
		},
		updateTag(data) {
			this.orgIds = [];
			for (const org of data) {
				this.orgIds.push(org.id);
			}
			this.auditForm.orgList = data;
			if (this.auditForm.orgList.length > 0) {
				this.$refs['auditForm'].clearValidate();
			}
		},
		updateStaffTag(data) {
			this.auditForm.userList = data;
			// this.auditForm.userList = this.auditForm.userList.concat(data);
			if (this.auditForm.userList.length > 0) {
				this.$refs['auditForm'].clearValidate();
			}
		},
		// 获取所有表单
		getApprovalAll() {
			api.getApprovalAll({ request: { pageNo: 1, pageSize: 100 } }).then((res) => {
				this.boxList = res.list;
				for (const box of this.boxList) {
					if (box.approvalInitStatus == 0) {
						box.approvalInitStatus = true;
					} else {
						box.approvalInitStatus = false;
					}
				}
			});
		},
		handleRoleClose(node, index) {
			this.$refs.roleOptions.setChecked(node.id, false);
			this.auditForm.roleList.splice(index, 1);
		},
		openedDialog() {
			if (this.subType) {
				if (this.activeType) {
					this.active = this.activeType;
				}

				this.handleCommand({ type: 'base', subType: this.subType, approvalInfoId: this.approvalInfoId || this.auditForm.id });
			}
		},
		handleCommand({ type, subType, approvalInfoId }) {
			const loading = Loading.service({ target: '.formClass' });

			api.getConfig({ request: { subType, approvalInfoId } }).then((res) => {
				loading.close();
				if (res?.code) {
					return;
				}
				this.auditForm = res;
				if (res.roleList && res.roleList.length > 0) {
					this.$refs.roleOptions.setCheckedKeys(
						res.roleList.map((v) => `${v.id}`),
						true
					);
				} else {
					this.$refs.roleOptions?.setCheckedKeys([]);
				}
			});
			if (type === 'base') {
				this.$nextTick(() => {
					this.$refs['auditForm']?.clearValidate();
				});
			}
		},
		// 审批人提交
		submitApproval() {
			if (this.cardList.length == 0) {
				this.$message.error('请至少添加一位审批人');
				this.loading = false;
				return;
			}
			if (!this.cardList.find(({ isPolicy }) => Number(isPolicy) === 1)?.jobId) {
				this.$message.warning('请选择决策岗位');
				this.loading = false;
				return;
			}
			const arr = [];

			for (const card of this.cardList) {
				arr.push({ userId: card.userId, jobId: card.jobId, approvalType: card.approvalType, isPolicy: card.isPolicy });
			}
			const params = {
				request: {
					approvalInfoId: this.auditForm.id,
					approvalProcessDtoList: arr
				}
			};

			this.loading = true;
			api.saveProcess(params).then((res) => {
				if (res.code === 0) {
					this.$message.success('提交成功');
					// this.active++;
					this.dialogFormVisible = false;
					this.active = 0;
				}
				this.loading = false;
			});
		},
		// 表单提交
		submitFormApproval() {
			this.loading = true;
			// 审批表单
			this.formList.basicPropertiesList.approvalPropertyPosition = '0';
			const tempArr = JSON.parse(JSON.stringify(this.formList.applyPropertiesList));

			for (const arr of tempArr) {
				if (arr.approvalPropertyType == '5' || arr.approvalPropertyType == '6' || arr.approvalPropertyType == '10') {
					// eslint-disable-next-line no-undef
					if (isArray(arr.approvalPropertyDefaultValue)) {
						arr.approvalPropertyDefaultValue = JSON.stringify(arr.approvalPropertyDefaultValue);
					}
				}
			}
			// 把基本信息合并在一起提交
			const arr = [...this.formList.basicPropertiesList];

			for (const item of arr) {
				tempArr.push(item);
			}

			const params = {
				request: {
					approvalInfoId: this.approvalInfoId || this.auditForm.id,
					approvalPropertiesDtoList: tempArr
				}
			};

			return api.saveProperty(params).then((res) => {
				if (res.code == 0) {
					this.active++;
				}
				this.loading = false;
			});
		},
		saveConfig() {
			this.$refs['auditForm'].validate((valid) => {
				if (valid) {
					// 表单提交
					if (this.active === 1) {
						this.submitFormApproval();
						return;
					}
					// 审批人提交
					if (this.active === 2) {
						this.submitApproval();
						return;
					}
					this.loading = true;
					api.saveConfig({ request: this.auditForm }).then((res) => {
						this.loading = false;
						if (res._responseStatusCode != 0) {
							return;
						}
						if (this.haveForm) {
							this.active = 1;
						} else {
							this.active = 2;
						}
						this.$refs['auditForm'].clearValidate();
						this.$refs['auditForm'].resetFields();
						this.handleCommand({ type: 'base', subType: this.subType, approvalInfoId: res.id || this.approvalInfoId });
						// this.dialogFormVisible = false;
						// this.$emit('close');
					});
				}
			});
		},
		closeModal() {
			// this.$refs['auditForm'].clearValidate();
			// this.$refs['auditForm'].resetFields();
			if (this.active === 2) {
				return (this.active = this.haveForm ? 1 : 0);
			}
			if (this.active === 1) {
				return (this.active = 0);
			}
			this.dialogFormVisible = false;
			this.$emit('close');
		},
		cancel() {
			this.active = 0;
			this.auditForm = {
				id: '',
				orgList: [],
				userList: [],
				roleList: []
			};
			this.$emit('close');
			this.$emit('refresh');
		},
		handleOrgClose(tag, index) {
			this.auditForm.orgList.splice(this.auditForm.orgList.indexOf(tag), 1);
			this.orgIds.splice(index, 1);
		},
		handleUserClose(tag) {
			this.auditForm.userList.splice(this.auditForm.userList.indexOf(tag), 1);
		},
		updateList(list) {
			this.cardList = list;
		},
		updateInfo(list) {
			this.formList = list;
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	height: 100%;
	flex-wrap: wrap;
	.formClass {
		.viewbox {
			margin: 0;
		}
	}
	.boxes {
		display: flex;
		flex-flow: row wrap;
	}

	.box {
		background: #fff;
		margin-bottom: 20px;
		border-radius: 5px;
		position: relative;
		padding: 10px 0 40px 0;
		.seticon {
			text-align: right;
			padding-right: 10px;
			color: #909399;
			i {
				font-size: 20px;
			}
		}
		.setBox {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-direction: row;
			.boxicon {
				text-align: center;
				// margin-right: 20px;
				.boxicon-img {
					text-align: center;
				}
				img {
					width: 60px;
					height: 60px;
					// width: 100%;
				}
				p {
					margin: 0;
					margin-top: 20px;
					font-size: 18px;
				}
				.box-Btn {
					text-align: center;
				}
			}
		}
		.newBox {
			font-size: 40px;
			color: #eee;
			text-align: center;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&:hover {
			cursor: pointer;
		}
	}
}
.svg {
	width: 50px;
	height: 40px;
}
</style>
